<template>
  <el-row v-loading="loading">
    <h2>
      Applications
      <el-button size="mini" icon="el-icon-plus" circle class="addapp" @click="createApp()"></el-button>
    </h2>

    <div class="block" v-if="apps && apps.results">
      <el-pagination
        layout="sizes, prev, pager, next"
        :total="apps.count"
        :page-sizes="[10, 25, 50, 100]"
        :page-size="25"
        @size-change="(size) => { this.limit = size; loadPage(this.page) }"
        @current-change="loadPage">
      </el-pagination>
    </div>
    <el-table :data="apps.results" width="100%"  v-loading="tableLoading">
      <el-table-column prop="id" width="64">
        <template slot-scope="scope">
          <router-link class="myClass" :to="`/admin/app/${scope.row.id}`">
            <el-button type="primary" icon="el-icon-edit" circle/>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="64" sortable>
        <template slot-scope="scope">
          <el-tag>{{ scope.row.id }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$t('g.name')" sortable></el-table-column>
      <el-table-column prop="is_public" label="API Publique" width="128" sortable>
        <template slot-scope="scope">
          <i v-if="scope.row.is_public" class="color-ok el-icon-success"/>
          <i v-else class="color-ko el-icon-error"/>
        </template>
      </el-table-column>
      <el-table-column prop="disabled" label="Actif" width="128" sortable>
        <template slot-scope="scope">
          <i v-if="!scope.row.disabled" class="color-ok el-icon-success"/>
          <i v-else class="color-ko el-icon-error"/>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<script>
import axios from 'axios'
import { config } from '../../config'
import { DEFAULT_APP, DEFAULT_LAYOUT } from '../../defaults'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'AdminApps',

  data () {
    return {
      loading: false,
      tableLoading: false,
      limit: 25,
      offset: 0,
      showCreate: false,
      newEmail: '',
      newName: '',
      newPassword: '',
      appID: null,
      apps: {}
    }
  },

  computed: {
  },

  methods: {
    createApp () {
      this.$prompt('Titre de l\'application', 'Créer une application', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /^[\w -,&]+$/,
        inputErrorMessage: 'Nom de l\'application invalide'
      }).then((value) => {
        this.loading = true;
        let payload = cloneDeep(DEFAULT_APP);
        payload['name'] = value.value;
        axios.post(`${config.baseUrl}/apps/`, payload)
        .then((response) => {
          this.$notify({
            title: 'Application créée',
            message: `L'application a été créée: ID ${response.data.id}`
          })
          this.appID = response.data.id
          this.createLayout()
        }).catch(error => {
          this.loading = false;
          this.$notify({
            title: 'Echec création',
            message: `Erreur de création de l'application (${error})`,
            type: 'error'
          })
        })

      }).catch(function () {})
    },

    createLayout () {
      let payload = cloneDeep(DEFAULT_LAYOUT);
      payload["is_selected"] = true
      axios.post(`${config.baseUrl}/apps/${this.appID}/layouts/`, payload)
      .then((response) => {
        this.$notify({
          title: 'Layout créé',
          message: `Nouveau layout: ${response.data.id}`
        })
        this.$router.push({path: `/admin/app/${this.appID}`})
      }).catch(error => {
        this.loading = false;
        this.$notify({
          title: 'Echec création',
          message: `Erreur de création du layout (${error})`,
          type: 'error'
        })
      })
    },

    async loadPage (page) {
      this.tableLoading = true
      this.offset = this.limit * (page - 1)
      axios.get(`${config.baseUrl}/apps/`, {
        params: {
          ordering: 'id',
          limit: this.limit || 100,
          offset: this.offset || 0
        }
      })
      .then((response) => {
        this.apps = response.data
      })
      this.tableLoading = false
    }
  },

  async created () {
    this.loading = false
    this.loadPage(0)
  }
}
</script>

<style scoped>
.addapp {
  float: right;
  margin-left: 10px;
}
</style>
<style>
.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
}

.color-ok {
  color: #4caf50;
  font-size: 24px;
}
.color-ko {
  color: #f44336;
  font-size: 24px;
}
</style>
