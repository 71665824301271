<template>
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="16" :xl="10">
    <h2>
      <el-tag type="info" size="mini">{{ app.id }}</el-tag>
      Application: {{ app.name }}
    </h2>
    <el-row v-loading="loading">
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('g.section_general')">

          <label class="form-item">Nom de l'application</label>
          <el-row>
            <el-input v-model="app.name" @change="updateApp('name', $event)"/>
          </el-row>

          <label class="form-item">Status de l'application</label>
          <el-row>
              <el-col :span="8">
              <el-checkbox
                :value="app.disabled"
                @change="updateApp('disabled', $event)">Désactivée</el-checkbox>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="8">
              <el-checkbox
                :value="app.is_public"
                @change="updateApp('is_public', $event)">Accès publique</el-checkbox>
              </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Utilisateurs">
          <label class="form-item">{{ $t("g.name") }}</label>
          <el-row>
            <el-input
              v-model="staffName"
              type='text'>
            </el-input>
          </el-row>
          <label class="form-item">{{ $t("login.email") }}</label>
          <el-row>
            <el-input
              v-model="staffEmail"
              type='email'>
            </el-input>
          </el-row>
          <label class="form-item">{{ $t("login.password") }}</label>
          <el-row>
            <el-input
              v-model="staffPassword"
              type='text'>
            </el-input>
          </el-row>
          <el-row>
            <el-button type="primary" @click="addStaffUser()">Ajouter un utilisateur staff</el-button>
          </el-row>

          <label class="form-item">Utilisateurs</label>
          <el-row>
            <el-table :data="users">
              <el-table-column prop="name" :label="$t('g.name')"></el-table-column>
              <el-table-column prop="email" :label="$t('login.email')"/>
              <el-table-column prop="is_staff" label="Staff" width="128" sortable>
                <template slot-scope="scope">
                  <div class="cursor" @click="setUserField(scope.row.id, 'is_staff', !scope.row.is_staff)">
                    <i v-if="scope.row.is_staff" class="color-ok el-icon-success"/>
                    <i v-else class="color-ko el-icon-error"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="is_moderator" label="Moderateur" width="128" sortable>
                <template slot-scope="scope">
                  <div class="cursor" @click="setUserField(scope.row.id, 'is_moderator', !scope.row.is_moderator)">
                    <i v-if="scope.row.is_moderator" class="color-ok el-icon-success"/>
                    <i v-else class="color-ko el-icon-error"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="is_contributor" label="Contributeur" width="128" sortable>
                <template slot-scope="scope">
                  <div class="cursor" @click="setUserField(scope.row.id, 'is_contributor', !scope.row.is_contributor)">
                    <i v-if="scope.row.is_contributor" class="color-ok el-icon-success"/>
                    <i v-else class="color-ko el-icon-error"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="id" width="64">
                <template slot-scope="scope">
                  <el-button @click="deleteUser(scope.row.id)" type="danger" icon="el-icon-delete" circle/>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>

        <el-tab-pane :label="$t('g.section_debug')">
          <el-row>
            <h2>Application JSON</h2>
            <pre>{{this.$store.state.app}}</pre>
          </el-row>
        </el-tab-pane>
      </el-tabs>

    </el-row>

    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'
import { config } from '../../config'
import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'AdminApp',
  components: { },
  props: {
    appID: {
      type: Number
    }
  },

  data () {
    return {
      loading: true,
      app: {},
      staffName: '',
      staffEmail: '',
      staffPassword: '',
      users: []
    }
  },

  methods: {

    async updateApp (field, value) {
      const payload = {}
      payload[field] = value
      Vue.set(this.app, field, value)
      await axios.patch(`${config.baseUrl}/apps/${this.appID}/`, payload)
    },

    loadUsers () {
      axios.get(`${config.baseUrl}/apps/${this.appID}/users/?limit=5000`).then((response) => {
        this.users = response.data.results;
      })
    },

    async prepare () {
      this.loading = true
      await this.$store.dispatch('ensureApp', {
        appID: this.appID
      })
      this.loadUsers();
      this.app = cloneDeep(this.$store.state.app)
      this.loading = false
    },

    addStaffUser () {
      const payload = {
        email: this.staffEmail,
        password: this.staffPassword,
        name: this.staffName,
        is_staff: true,
        is_moderator: true,
      }
      axios.post(`${config.baseUrl}/apps/${this.appID}/users/`, payload)
      .then((response) => {
        this.$notify({
          title: 'Utilisateur créé',
          message: `Nouvel utilisateur ${response.data.id}: ${this.staffEmail}`,
          type: 'success'
        })
        this.staffEmail = ''
        this.staffPassword = ''
        this.staffName = ''
        this.loadUsers()
      }).catch(error => {
        this.loading = false;
        this.$notify({
          title: 'Echec création',
          message: `Erreur de création de l'application (${error})`,
          type: 'error'
        })
      })
    },

    deleteUser (userID) {
      this.$confirm(
        'Vous allez supprimer cet utilisateur de manière définitive. Continuez?',
        'Attention !', {
          confirmButtonText: 'Oui, je le supprime!',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }).then(() => {
          axios.delete(`${config.baseUrl}/apps/${this.appID}/users/${userID}`)
          .then((response) => {
            this.$notify({
              title: 'Utilisateur supprimé',
              message: `Utilisateur ${userID} supprimé`,
              type: 'success'
            })
            this.loadUsers()
          })
          .catch(error => {
            this.loading = false;
            this.$notify({
              title: 'Echec suppression',
              message: `Erreur de suppression de l'utilisateur (${error})`,
              type: 'error'
            })
          })
        })
    },

    setUserField (userID, field, value) {
      axios.patch(
        `${config.baseUrl}/apps/${this.appID}/users/${userID}/`, { [field]: value })
        .then(() => {
          this.$notify({
            title: 'Changement effectué',
            message: `Changement de l'utilisateur ${userID} sur le champ ${field}`,
            type: 'success'
          })
          this.loadUsers()
        })
        .catch(error => {
          this.$notify({
            title: 'Echec changement',
            message: `Erreur de changement de l'utilisateur (${error})`,
            type: 'error'
          })
        })
    },
  },

  computed: {
  },

  watch: {
    '$route' (from, to) {
      this.prepare()
    }
  },

  mounted () {
    this.prepare()
  }
}
</script>

<style scoped>
h2 {
  display: flex;
  align-items: center;
}
h2 span.el-tag {
  margin-right: 5px;
}

.color-ok {
  color: #4caf50;
  font-size: 24px;
}
.color-ko {
  color: #f44336;
  font-size: 24px;
}

.cursor {
  cursor: pointer;
}
</style>
