const DEFAULT_APP = {
    languages: [{
        code: 'fr',
        label: 'Français'
    }],
    fields: {
        map_default_content_fields: [],
        poi_default_content_fields: [],
        user_default_profile_fields: [{
                type: "string",
                field_name: "first_name",
                displayed_name: {
                    en: "Fist name",
                    fr: "Prénom"
                }
            },
            {
                type: "string",
                field_name: "last_name",
                displayed_name: {
                    en: "Last name",
                    fr: "Nom"
                }
            },
            {
                type: "string",
                field_name: "country",
                displayed_name: {
                    en: "Country",
                    fr: "Pays"
                }
            },
            {
                type: "string",
                field_name: "zip_code",
                displayed_name: {
                    en: "Zip code",
                    fr: "Code postal"
                }
            },
            {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        ],
        map_default_translated_fields: [{
                type: "string",
                field_name: "title",
                displayed_name: {
                    en: "Title",
                    fr: "Titre"
                }
            },
            {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        ],
        poi_default_translated_fields: [{
                type: "string",
                field_name: "title",
                displayed_name: {
                    en: "Title",
                    fr: "Titre"
                }
            },
            {
                type: "longstring",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        ],
        category_default_content_fields: [],
        selection_default_content_fields: [],
        category_default_translated_fields: [{
                type: "string",
                field_name: "name",
                displayed_name: {
                    en: "Name",
                    fr: "Nom"
                }
            },
            {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        ],
        selection_default_translated_fields: [{
                type: "string",
                field_name: "name",
                displayed_name: {
                    en: "Name",
                    fr: "Nom"
                }
            },
            {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        ]
    },
}

const DEFAULT_LAYOUT = {
    name: 'Layout initial',
    layout_fields: {
        media: {
            font_name1: {
                name: "Poppins-Light.ttf",
                type: "file",
                filename: "17/layout/bf1a7f964eb1751f56419265ce6650e6476eea7cc6e6573fa57573dcc52b5312.ttf"
            },
            font_name2: {
                name: "Poppins-Bold.ttf",
                type: "file",
                filename: "17/layout/ec26b37105adacad70aca3692f80e3dab073f06639daa80f8adcede6af60d76c.ttf"
            },
            font_name3: {
                name: "Poppins-Regular.ttf",
                type: "file",
                filename: "17/layout/577dfb678b8fc1bda8ef0fabd74611675013ae08ae3b0045b8adcb1c6fa01eb9.ttf"
            },
            font_name4: {
                name: "Poppins-ExtraBold.ttf",
                type: "file",
                filename: "17/layout/cd000441115e695663a59449a9b87e5ba27594ffce0f250e8e25433a4682c01f.ttf"
            },
            font_name5: {
                name: "traulha.otf",
                type: "file",
                filename: "17/layout/c83514a56d1ed73339a4dea278607faba1c55c4a2bcd7d9a1eaa0ed7b5575709.otf"
            }
        }
    },
    displayed_fields: {
        map_default_content_fields: {},
        poi_default_content_fields: {},
        user_default_profile_fields: {
            0: {
                type: "string",
                field_name: "first_name",
                displayed_name: {
                    en: "First name",
                    fr: "Prénom"
                }
            },
            1: {
                type: "string",
                field_name: "last_name",
                displayed_name: {
                    en: "Last name",
                    fr: "Nom"
                }
            },
            2: {
                type: "string",
                field_name: "country",
                displayed_name: {
                    en: "Country",
                    fr: "Pays"
                }
            },
            3: {
                type: "string",
                field_name: "zip_code",
                displayed_name: {
                    en: "Zip code",
                    fr: "Code postal"
                }
            },
            4: {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        },
        map_default_translated_fields: {
            0: {
                type: "string",
                field_name: "title",
                displayed_name: {
                    en: "Title",
                    fr: "Titre"
                }
            },
            1: {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        },
        poi_default_translated_fields: {
            0: {
                type: "string",
                field_name: "title",
                displayed_name: {
                    en: "Title",
                    fr: "Titre"
                }
            },
            5: {
                type: "longstring",
                field_name: "description",
                displayed_name: {
                    en: "Long description",
                    fr: "Description longue"
                }
            }
        },
        category_default_content_fields: {},
        selection_default_content_fields: {},
        category_default_translated_fields: {
            0: {
                type: "string",
                field_name: "name",
                displayed_name: {
                    en: "Name",
                    fr: "Nom"
                }
            },
            1: {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        },
        selection_default_translated_fields: {
            0: {
                type: "string",
                field_name: "name",
                displayed_name: {
                    en: "Name",
                    fr: "Nom"
                }
            },
            1: {
                type: "string",
                field_name: "description",
                displayed_name: {
                    en: "Description",
                    fr: "Description"
                }
            }
        }
    }
}

export {
    DEFAULT_APP,
    DEFAULT_LAYOUT,
}